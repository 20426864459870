@use './../../SCSS/utils/variables' as *;

.otherIncomeModal-container{
    *:not(.otherIncomeModal-title) {
        font-size: 14px;
    }
    .otherIncomeModal-title{
        font-size: 16px;
    }
    .ticket-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        max-height: 425px;
        overflow-y: auto;
        overflow-x: auto;
        border: 1px solid $lightGray;
        border-radius: 8px;
        padding: 10px;
    
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
    
        &::-webkit-scrollbar-track {
            background: $lowWhite;
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $purple;
            border-radius: 4px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
            background: $purpleOpacity;
        }
    
        .ticket {
            border: 1px solid $purple;
            border-radius: 5px;
            padding: 15px;
            background-color: $white;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            position: relative;
            min-width: 100%;
            justify-content: space-between;
    
            .ticket-header {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 16px;
            }
    
            .ticket-body {
                font-size: 14px;
                color: $gray2F;
                margin-bottom: 10px;
            }
        }
    }
    .otherIncomeModal-form-container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
        border: 1px solid $lightGray;
        border-radius: 8px;
        padding: 10px;
    }
    
    .otherIncomeDatepicker {
        bottom: -22px;
        position: relative;
    }
    
    .btn-purple{
        background-color: $purple !important;
        background: $purple !important;
        color: $white !important;
    }
    
    .btn-purple:hover{
        background-color: $purpleOpacity !important;
        background: $purpleOpacity !important;
    }
    
    .btn-purple-dark{
        background-color: $borderGray !important;
        background: $borderGray !important;
        color: $white !important;
    }
    
    .btn-purple-dark:hover{
        background-color: $purple !important;
        background: $purple !important;
    }
}