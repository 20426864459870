@use './../../../../../../src/SCSS/utils/variables' as *;

//general
.attendancesFilters {
	padding: 8px 0;
	flex-wrap: wrap;
	width: 100%;
	.attendancesFiltersContent {
		flex-wrap: wrap;
		flex-grow: 1;
		.title {
			padding-right: 32px;
			h3 {
				margin-bottom: 0;
				font-size: 18px;
			}
		}
		.filterCard {
			margin: 8px 24px 8px 0;
			flex-direction: column;
			.title {
				margin-bottom: 5px;
				padding-right: 12px;
				font-size: 12px;
			}
			.selectControl{
				min-width: 180px;
				min-height: 30px;
				max-height: 30px;
				font-size: 12px;
			}
			.selectDropdownIndicator{
				padding: 0 8px;
			}
			.selectOption{
				font-size: 12px;
			}
		}
	}
}

//mediaqueries en orden descendente
@media ($media_lg) {
	.attendancesFilters{
		padding: 8px 20px;
        margin-left: -20px;
        width: calc(100% + 40px);
		.attendancesFiltersContent {
			.filterCard{
				margin: 4px 12px 4px 0;
				.title{
					font-size: 12px;
				}
				.selectControl {
					font-size: 10px;
					min-width: 150px;
					min-height: 25px;
					max-height: 25px;
				}
				.selectDropdownIndicator {
					padding: 0 4px;
				}
				.selectOption {
					font-size: 10px;
				}
			}
		}
    }
}
