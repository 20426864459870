@use './../../../../../SCSS/utils/variables' as *;

//general

.modal-body{
    #attendances-tabs{
        position: sticky;
        left: 0;
    }
    .historic-tab {
        table{
            &.specialTable{
                thead th,
                tr td {
                    button {
                        border: none;
                        opacity: 1;
                    }
                }
                .sessionExtraStudentCount{
                    .studentCount{
                        button{
                            background-color: transparent;
                            svg {
                                font-size: 14px;
                            }
                            span {
                                font-size: 16px;
                                margin-left: 5px;
                            }
                            &:not(.noExtraStuden){
                                color: $link;
                            }
                            &.noExtraStuden{
                                cursor: default;
                            }
                        }
                    }
                }
                .sessionExtraStudentCount,
                .sessionDocuments{
                    td{
                        border: none !important;
                        &:first-child{
                            color: $purple;
                        }
                        &:first-child,
                        &:last-child{
                            width: auto;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }

    .add-day-form {
        .submit-button {
            background-color: #198754;
            border-color: #198754;
            color: #fff;
            width: 100px;
            margin: 0 auto;
            display: block;

            &:hover {
                background-color: darken(#198754, 5%);
                border-color: darken(#198754, 5%);
            }
        }
    }
}
.extraStudentTable{
    tbody td {
        font-size: 14px;
        margin: 0;
        p{
            margin: 0;
        }
    }
}

.add-day-button {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: #198754;
    border-color: #198754;
    display: block;
    margin: 0 auto;

    &:hover {
        background-color: darken(#198754, 5%);
        border-color: darken(#198754, 5%);
    }

    &:disabled {
        opacity: 0.65;
    }
}

.edit-day-button {
    padding: 0 !important;
    color: $gray !important;
    font-size: 1rem !important;
    min-width: 30px;
    height: 30px;
    border-radius: 50% !important;
    background-color: $grayE7 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $lightGray !important;
        color: $black !important;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

//mediaqueries en orden descendente

@media ($media_lg) {
    .modal-body{
        #attendances-tabs{
           font-size: 12px;
           .nav-tabs .nav-link{
            padding: 4px 8px;
        }
        }
        .historic-tab {
            table{
                &.specialTable{
                    td{
                        &.sticky{
                            width: auto;
                            min-width: 130px;
                            max-width: 130px;
                            position: sticky !important;
                            left: 0;
                        }
                    }
                    th{
                        &.sticky{
                            width: auto;
                            position: sticky !important;
                            left: 0;
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }
}