//MEDIA QUERIES

/* Extra pequeño (móviles) */
$media_sm: "max-width: 576px";
/* Pequeño (teléfonos grandes o pequeños tablets) */
$media_md:  "max-width: 768px";
/* Mediano (tabletas y pequeños desktops) */
$media_lg: "max-width: 992px";
/* Extra grande (pantallas grandes como monitores 4K) */
$media_xl: "min-width: 1200px";

//COLORS
$black: #000000;
$black10: #00000010;
$black40: #00000040;
$white: #ffffff;
$lowWhite: #f1f1f1;
$gray: #808080;
$lightGray: #d3d3d3;
$gray2F: #2f2f2f;
$gray45: #455560;
$grayE7: #e7e7e7;
$purple: #8f91da;
$purpleOpacity: #6a6cb2;
$link: #349dff;
$blueDay: #00b8d9;
$blueCharge: #2196F3;
$blueEdit: #d5f7ff;
$redDay: #ff3a3a;
$greenSuccess: #28a745;
$greenActive: #00e669;

//COLORS IN CONSTANTS
$grayOpacity: #AAB0BB;
$borderGray: #141535;
$totalGray: #979797;
$lowGray: #939393;
$red: #C32722;
$redLine: #F13731;
$darkRed: #8D0606;
$redButtonClose: #7A1A17;
$dark: #2F316B;
$darkGray: #5154AE;
$darkBlue: #2878A2;
$orange: #FF6600;
$green: #00FF00;
$transparent: #00000000;

//FONTS
$robotoNormal10: normal 10px Roboto;
$robotoNormal12: normal 12px Roboto;
$robotoNormal14: normal 14px Roboto;
$robotoNormal16: normal 16px Roboto;
$robotoNormal37: normal 37px Roboto;
$robotoNormal20: normal 20px Roboto;
$robotoMedium14: medium 14px Roboto;
$robotoMedium16: medium 16px Roboto;
$robotoMedium20: medium 20px Roboto;
$robotoBold10: bold 10px Roboto;
$robotoBold12: bold 12px Roboto;
$robotoBold14: bold 14px Roboto;
$robotoBold15: bold 15px Roboto;
$robotoBold16: bold 16px Roboto;
$robotoBold20: bold 20px Roboto;
$robotoBold24: bold 24px Roboto;
$robotoBold28: bold 28px Robot;

//BACKGROUNDS
$bgBlack: $black;
$bgGrayOpacity: $grayOpacity;
$bgRed: $red;
$bgRedLine: $redLine;
$bgDarkRed: $darkRed;
$bgWhite: $white;
$bgGray: $gray;
$bgPurple: $purple;
$bgLowGray: $lowGray;
$bgTransparent: $transparent;
$bgTedButtonClose: $redButtonClose;