@use './../../../../src/SCSS/utils/variables' as *;

//general
.miniModal {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    background-color: $black40;
    height: 100vh;
    width: 100%;
    z-index: 10;
    padding: 20px 15px;
    .miniModalContainer {
        flex-direction: column;
        display: flex;
        background-color: $white;
        border-radius: 15px;
        padding: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        max-height: 100%;
        max-width: 100%;
        .miniModalHeader {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .title {
                color: $gray45;
                width: 100%;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
            .close {
                width: 0;
                .closeBtn {
                    background-color: $gray2F;
                    border-radius: 50%;
                    min-width: 30px;
                    height: 30px;
                    color: $white;
                    font-size: 6px;
                    transform: translate(-50%, -50%);
                    &:disabled {
                        background-color: $gray;
                        cursor: default;
                    }
                }
            }
        }
        .miniModalBody {
            margin-top: 0px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }
        
            &::-webkit-scrollbar-track {
                background: $lowWhite;
                border-radius: 4px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: $purple;
                border-radius: 4px;
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background: $purpleOpacity;
            }
        }
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {
    .miniModal {
        padding: 15px 10px;
        .miniModalContainer {
            padding: 15px;
            .miniModalHeader {
                margin-bottom: 5px;
                .title {
                    font-size: 16px;
                }
                .close {
                    width: 0;
                    .closeBtn {
                        min-width: 25px;
                        height: 25px;
                        font-size: 5px;
                        transform: translate(-65%, -35%);
                    }
                }
            }
        }
    }
}

.loadingBarMiniModal {
    width: calc(100% - 24px);
    height: 6px;
    background: linear-gradient(90deg, $blueCharge, $purpleOpacity);
    border-radius: 5px;
    overflow: hidden;
    margin: 0 12px 12px 12px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, $purpleOpacity, $blueCharge);
        animation: loading 2s linear infinite;
    }
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}