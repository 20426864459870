.inputContainer
{
	height        : 47px;
	position      : relative;
	margin-bottom : 15px;
}

.inputContainer input:not(.employee-invoice-switch .form-check-input#bool_accounting), 
.inputContainer input:focus:not(.employee-invoice-switch .form-check-input#bool_accounting)
{
	bottom        : 0px;
	position      : absolute;
	border-bottom : 2px solid #8F91DA;
	border-style  : solid;
	width         : 100%;
	background    : none;
	font-weight   : 500;
	color         : var(--main-txt-color);
}

.inputContainer input:not(.employee-invoice-switch .form-check-input#bool_accounting)::-webkit-outer-spin-button, 
.inputContainer input:not(.employee-invoice-switch .form-check-input#bool_accounting)::-webkit-inner-spin-button
{
	-webkit-appearance : none;
	margin             : 0;
}

/* Firefox */
.inputContainer input[type=number]:not(.employee-invoice-switch .form-check-input#bool_accounting)
{
	-moz-appearance : textfield;
}

.react-tel-input
{
	height : 47px;
}

.react-tel-input .form-control:focus
{
	box-shadow : initial !important;
}

.react-tel-input .form-control
{
	bottom         : 0px !important;
	border-width   : 0px 0px 2px 0px !important;
	border-style   : solid !important;
	border-color   : #8f91da !important;
	border-radius  : 0px !important;
	width          : 100% !important;
	background     : none !important;
	padding-bottom : 0px !important;
	position       : absolute !important;
	padding-left   : 0px !important;
	padding-right  : 0px !important;
	text-align     : end !important;
}

.react-tel-input .flag-dropdown
{
	background : none !important;
	border     : none !important;
	bottom     : 0px !important;
	left       : 0px !important;
	top        : initial !important;
	height     : 47px !important;
}

.react-tel-input .selected-flag
{
	background-color : none !important;
	background       : none !important;
}

.react-tel-input .selected-flag:hover
{
	background-color : none !important;
	background       : none !important;
}

.react-tel-input .selected-flag .flag
{
	bottom        : 6px !important;
	top           : initial !important;
	left          : 0px !important;
	border-radius : 11px 13px 6px 5px !important;
}

.react-tel-input .country-list .country:hover
{
	background-color : #8F91DA !important;
}

.react-tel-input .country-list .country.highlight
{
	background-color : #8D91DA !important;
}