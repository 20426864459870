@use './../../../../../src/SCSS/utils/variables' as *;

//general
.attendancesContainer {
    padding : 30px;
    .header-filters{
        position: sticky;
        top: -1px;
        background-color: $white;
        z-index: 1;
        margin-top: -30px;
        .searchInputContainer{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .downloadMentorsSalaryReportContainer{
        width: 100%;
        display: flex;
        justify-content: end;
        .downloadMentorsSalaryReport{
            width: 115px;
            top: -38px;
            position: relative;
        }
    }
    .datePickerContainer{
        min-width: 240px;
        .gary-border{
            border: 1px solid $gray;
            border-radius: 5px;
        }
    }
    .pageHeader {
        font-size: 20px;
        margin-bottom: 0;
    }

    .noResult {
        padding: 25px;
    }

    .containerTable {
        padding: 10px 10px 0px 10px;
    }

    .tableComponent {
        font-size: 14px;
    }

    .attendancesPagination {
        display: flex;
        justify-content: center;
        position: sticky;
        bottom: 0;
        background: $white;
    }
}

//mediaqueries en orden descendente
@media ($media_lg) {
    .attendancesContainer {
        padding: 20px;
        .downloadMentorsSalaryReportContainer{
            .downloadMentorsSalaryReport{
                width: 38px;
            }
        }
        .pageHeader {
            font-size: 16px;
        }
    }
}